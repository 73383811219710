$firstAnimation: 1.5s;
$secondAnimation: 1s;

@keyframes yAxisStart {
  20% {
    top: -20%;
  }
  50% {
    top: calc(100vh - 480px);
  }
  100% {
    top: calc(140vh - 430px);
  }
}

@keyframes yAxisEnd {
  50% {
    top: -20%;
  }
  100% {
    top: 0;
  }
}

@keyframes xAxisStart {
  25% {
    transform: translateX(-25%);
  }
  50% {
    transform: translateX(10%);
  }
  80% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(20%);
  }
}

@keyframes xAxisEnd {
  0% {
    transform: translateX(10%);
  }
  50% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}

@keyframes scaleStart {
  50% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(0.375);
    opacity: 1;
  }
}

@keyframes scaleEnd {
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes waterColor {
  50% {
    fill: $brand_waterblue;
  }
  100% {
    fill: $brand_waterblack;
  }
}

@keyframes landColor {
  50% {
    fill: $brand_neonblue;
  }
  100% {
    fill: $brand_neonyellow;
  }
}

@keyframes bkgdBorder {
  50% {
    fill: $brand_neonblue;
  }
  100% {
    fill: $brand_neonyellow;
  }
}

@keyframes slowFade {
  0% { 
    opacity: 0; 
  }
  50% { 
    opacity: 0; 
  }
  100% { 
    opacity: 1; 
  }
}

@keyframes delayedFlicker {
  0% { 
    opacity: 0; 
  }
  35% { 
    opacity: 0.5; 
  }
  40% { 
    opacity: 0; 
  }
  45% { 
    opacity: 0.25; 
  }
  50% { 
    opacity: 0; 
  }
  100% { 
    opacity: 1; 
  }
}

#globe-container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  z-index: 2;

  & .container {
    position: relative;
    height: 100%;
  }

  &.invisible {
    .d3-globe--animate-y {
      animation: yAxisStart $firstAnimation 1 ease-in-out;
      animation-fill-mode: both;
      pointer-events: none;
    }

    .d3-globe--animate-x {
      animation: xAxisStart $firstAnimation 1 ease-in-out;
      animation-fill-mode: both;
    }

    .d3-globe--animate-scale {
      animation: scaleStart $firstAnimation 1 ease-in-out;
      animation-fill-mode: both;
      pointer-events: all;
    }

    .d3-globe--land {
      animation: landColor $firstAnimation 1 ease-in-out;
      animation-fill-mode: both;
    }
    
    .d3-globe--fill {
      animation: waterColor $firstAnimation 1 ease-in-out;
      animation-fill-mode: both;
    }
    
    .d3-globe--border {
      animation: delayedFlicker $secondAnimation 1 ease-in-out;
      animation-fill-mode: both;
      animation-delay: $firstAnimation;
    }
    
    .d3-globe--text {
      animation: slowFade $secondAnimation 1 ease-in-out;
      animation-fill-mode: both;
      animation-delay: $firstAnimation;
    }
  
    .d3-globe--dots,
    .d3-globe--pins,
    .d3-globe--label {
      opacity: 0;
    }
  }

  &.visible {
    .d3-globe--animate-y {
      animation: yAxisEnd 0.75s 1 ease-in-out;
      animation-fill-mode: both;
    }

    .d3-globe--animate-x {
      animation: xAxisEnd 0.75s 1 ease-in-out;
      animation-fill-mode: both;
    }

    .d3-globe--animate-scale {
      animation: scaleEnd 0.75s 1 reverse ease-in-out;
      animation-fill-mode: both;
    }

    .d3-globe--border,
    .d3-globe--text {
      display: none;
    }
  }
}

.d3-globe {
  &--animate-y {
    position: absolute;
    right: 0;
    top: 0;
  }

  &--animate-x {
    position: relative;
    transform: translateX(25%);
  }

  &--animate-scale {
    position: relative;
    transition: transform 200ms ease-in-out;
  }
  
  &--border {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    border: 6px solid $brand_electricblue;
    background: $brand_nearblack;
    opacity: 0;
  }

  &--text {
    position: absolute;
    left: 0;
    top: -35rem;
    width: 100%;
    text-align: center;
    opacity: 0;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc(50% - 3px);
      width: 6px;
      height: 8rem;
      background: $brand_electricblue;
    }

    h2 {
      margin-top: 0;
      font-size: 14rem;
    }

    h4 {
      margin: 0;
      color: $brand_neonyellow;
      font-size: 7rem;
      line-height: 1;
    }
  }

  &--globe {
    display: block;
    padding: 50px;
  }

  &--land {
    pointer-events: bounding-box;
  }
  
  &--fill {
    fill: $brand_waterblue;
  }

  &--dots {
    fill: $brand_white;
    opacity: 1;

    transition: opacity 2s ease-in-out;
  }

  &--pins {
    fill: $brand_nasared;
    stroke: $brand_white;
    stroke-width: 2px;
    opacity: 1;

    transition: opacity 2s ease-in-out;
  }

  &--label {
    fill: $brand_white;
    font-family: $font-family-base;
    font-size: $font-size-base * 0.8;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    text-anchor: end;
    opacity: 1;

    transition: opacity 2s ease-in-out;
  }

  .hide {
    opacity: 0;
  }

  .show {
    opacity: 1;
  }
}
