

.grid {
  width: 100%;
  margin: 0 auto 2rem;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;

    &--default {
      margin: 0;

      .grid__col {
        margin: 0 0 3rem;
      }
    }

    &--center {
      justify-content: center;
    }

    &--start {
      justify-content: flex-start;
    }

    &--end {
      justify-content: flex-end;
    }
  }

  &__col {
    margin: 0;
  }

  $gutter: 2;
  $grid__cols: 12;
  @for $i from 1 through $grid__cols {
    &__col-#{$i} {
      flex-basis: ((100 / ($grid__cols / $i) ) - $gutter) * 1%;
    }
  }

  &__item {
    &--center {
      text-align: center;
    }
    &--left {
      text-align: left;
    }
    &--right {
      text-align: right;
    }
  }
}

.column {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 3rem;
}

@media screen and (min-width: 768px) {
  .column {
    width: 49%;
    margin-left: 2%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .column:nth-child(odd) {
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1239px) {
  .column {
    width: 32%;
    margin-left: 2%;

    &:nth-child(3n + 1) {
      margin-left: 0;
    }

    &.column-half {
      width: 49%;

      &:nth-child(odd) {
        margin-left: 0;
      }
    }
  }
}

@media screen and (min-width: 1240px) {
  .column {
    margin-left: 2%;

    &.column-half {
      width: 49%;

      &:nth-child(odd) {
        margin-left: 0;
      }
    }

    &.column-third {
      width: 32%;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }

    &.column-fourth {
      width: 23.5%;

      &:nth-child(4n + 1) {
        margin-left: 0;
      }
    }

    &.column-fifth {
      width: 18.4%;

      &:nth-child(5n + 1) {
        margin-left: 0;
      }
    }
  }
}

