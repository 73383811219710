.hero {
  position: relative;
  min-height: 850px;
  align-items: center;
  justify-content: center;
  background-color: $brand_electricblue;
  color: $brand_white;
  -webkit-font-smoothing: antialiased;

  // Set to none to allow clickable links in the globe animation
  pointer-events: none;
  
  @media screen and (min-height: 850px) {
    min-height: 100vh;
  }

  &__layer {
    position: relative;
    z-index: 1;
    bottom: 5%;
    width: 100%;
  
    @media screen and (min-width: $screen-lg-min) and (min-height: 960px){
      position: absolute;
    }

    &--base {
      display: none;
      position: absolute;
      z-index: 0;
      top: 0;
      bottom: 0;
      width: 100%;

      // Hero sets this to none, so we need to set it to all to override that
      pointer-events: auto;
  
      @media screen and (min-width: $screen-md-min) and (min-height: 750px) {
        display: block;
      }
    }
  }

  &__content {
    margin: 2rem 0;

    // Hero sets this to none, so we need to set it to all to override that
    pointer-events: auto;

    @media screen and (min-width: $screen-md-min) {
      flex-direction: row;
      justify-content: flex-start;
    }

    @media screen and (min-width: $screen-lg-min) {
      max-width: 80%;
    }

    .content {  
      h2 {
        margin: 0;
        font-size: $font-size-base * 2.2;
        font-weight: $font-weight-black;
        letter-spacing: 0.05em;
        line-height: 1.2;
        text-transform: uppercase;

        @media screen and (min-width: $screen-sm-min) {
          font-size: $font-size-base * 3.2;
        }
      }
  
      h3 {
        color: $brand_neonyellow;
        margin: 0;
        font-size: $font-size-base * 1.8;
        font-weight: $font-weight-base;

        @media screen and (min-width: $screen-sm-min) {
          font-size: $font-size-base * 2.1;
        }
      }
      
      h4 {
        font-family: $font-family-base;
        margin: 0;
        letter-spacing: 0.1em;
        font-size: $font-size-base * 1.2;
        line-height: 1.5;
        text-transform: uppercase;
      }
  
      p {
        margin-bottom: 0;
        font-size: $font-size-base;
        line-height: 1.5;
      }
  
      a:not(.cta) {
        display: inline-block;
        color: $brand_neonyellow;
        border-bottom: 2px solid $brand_neonyellow;
  
        &:hover,
        &:focus,
        &:active {
          color: $brand_white;
          border-bottom-color: $brand_white;
        }
      }
  
      .richtext-image {
        &.left {
          display: inline-block;
          margin-right: 4%;
          margin-bottom: 0;
          vertical-align: middle;
          max-width: 15%;
  
          + p,
          + h2,
          + h3,
          + h4 {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 0;
            max-width: 80%;
            @media screen and (min-width: $screen-md-min) {
              max-width: 35%;
            }
            @media screen and (min-width: $screen-lg-min) {
              max-width: 55%;
            }
          }
        }
      }
  
      .cta {
        margin: 0;
      }
    }
  }
}
