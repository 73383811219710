.site-menu {
  display: block;
  margin: 0;
  padding: 0;
  color: $brand_electricblue;

  @media screen and (min-width: $screen-menu-toggle) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  &--external {
    position: relative;

    .site-menu__sub-menu {
      display: none;

      @media screen and (min-width: $screen-menu-toggle) {
        display: block;
      }
    }
  }

  &__item {
    display: block;
    list-style: none;
    margin: 0 0 2.5rem 0;
    transition: border-color $transition;

    @media screen and (min-width: $screen-menu-toggle) {
      margin: 0 1.5rem;
      padding: 0;
      text-align: right;
    }

    &.active,
    &.ancestor {
      .site-menu__link {
        &::after {
          border-left-color: $brand_nearblack;
        }
      }

      &:hover {
        .site-menu__link {
          color: lighten($brand_nearblack, 25%);

          &::after {
            border-left-color: lighten($brand_nearblack, 25%);
          }
        }
      }
    }
  }

  &__heading {
    position: absolute;
    top: 2.5rem;
    display: block;
    left: 0;
    text-align: left;
    font-size: $font-size-base * 0.7;
    font-weight: $font-weight-bold;
    color: $brand_nasared;

    @media screen and (min-width: $screen-menu-toggle) {
      left: 3rem;
      white-space: nowrap;
    }
  }

  &__item {
    @media screen and (min-width: $screen-menu-toggle) {
      &:focus,
      &:hover {
        .site-menu__link {
          text-decoration: none;
          color: lighten($brand_nearblack, 25%);
    
          &::after {
            border-left-color: lighten($brand_nearblack, 25%);
            transform: scale(1.1) rotate(90deg);
          }
        }

        .sub-menu--slide-down {
          max-height: 500px;
          opacity: 1;
          z-index: 1;
          height: auto;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__link {
    position: relative;
    display: block;
    padding: 8px 0;
    color: $brand_white;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (min-width: $screen-menu-toggle) {
      color: $brand_nearblack;
      line-height: 2;
    }

    &--sub-menu {
      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 0.5rem solid $brand_nearblack;
        border-top: 0.5rem solid transparent;
        border-bottom: 0.5rem solid transparent;
        margin-top: 0;
        margin-left: 1rem;
        vertical-align: middle;

        transition: transform $transition, border-color $transition;
      }
    }
  }
}
