//
// Variables
// --------------------------------------------------

//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$white: #ffffff;
$black: #000000;
$transparent: #ffffff00;

$gray-base: #000;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: lighten($gray-base, 20%); // #333
$gray: lighten($gray-base, 33.5%); // #555
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: lighten($gray-base, 79.5%); // #777
$gray-lightest: lighten($gray-base, 90%); // #f2f2f2


// brand - colors
$brand_black: $black;
$brand_white: $white;
$brand_nearblack: #07173F;
$brand_electricblue: #0042A6;
$brand_neonblue: #0960E1;
$brand_waterblue: #00378B;
$brand_waterblack: #061b1a;
$brand_neonyellow: #EAFE07;
$brand_nasared: #EE293D;
$brand_mediumred: #C81628;
$brand_gradiant_blue: linear-gradient(135deg, #0042A6 23.96%, #07173F 100%);


// Vendor
$vendor_facebook: #3b5998;
$vendor_instagram: #405de6;
$vendor_twitter: #1da1f2;
$vendor_tumblr: #35465c;
$vendor_youtube: #ff0000;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $brand_electricblue;
//** Global text color on `<body>`.
$text-color: $brand_white;

//** Global textual link color.
$link-color: $brand_neonyellow;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: none;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

// Base Fonts
$font_helvetica: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$font_georgia: Georgia, "Times New Roman", Times, serif;
$font_helvetica_light: "HelveticaNeue-Light", "Helvetica Neue Light",
  "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

// Brand Fonts
$font_fira: 'Fira Sans', sans-serif;
$font_fira_code: 'Fira Code', monospace;
$font_overpass: 'Overpass', sans-serif;

$font-family-sans-serif: $font_overpass;
$font-family-serif: $font_georgia;

//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: $font_fira_code;
$font-family-base: $font-family-sans-serif;

$font-size-base: 2rem;
$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.8; // 36/18
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: $font_fira;
$headings-font-weight: 900;
$headings-line-height: 1.1;
$headings-color: inherit;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-demi: 600;
$font-weight-bold: 700;
$font-weight-black: 900;
$font-weight-base: $font-weight-normal;

$transition: 200ms ease-in-out;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
$screen-xs: 480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
$screen-md: 992px;
$screen-md-min: $screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min;

// CUSTOM ADDITION OF SCREEN-XL
$screen-xl: 1500px;
$screen-xl-min: $screen-xl;
$screen-xl-desktop: $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Re-used breakpoints
$screen-menu-toggle: $screen-lg-min;
