.tweet-block {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 2rem 0;

  &__tweets {
    order: 1;
    width: 45%;
  }

  &__content {
    order: 2;
    width: 55%;
    font-size: $font-size-base * 0.9;

    h2 {
      font-size: $font-size-base * 1.4;
      line-height: 1.4;
      text-transform: none;
    }
  }

  &--align-right {
    .tweet-block__tweets {
      order: 2;
      text-align: right;
    }

    .tweet-block__content {
      order: 1;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    flex-direction: column;
    margin: 0;

    &__tweets,
    &__content {
      width: 100%;
      margin-bottom: 3rem;
    }

    &--align-right,
    &--align-left {
      .tweet-block__tweets {
        text-align: center;
      }
    }
  }
}
