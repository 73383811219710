/* =================================================================================
~ Button
================================================================================= */

@mixin btn {
  font-family: $font-family-base;
  display: inline-block;
  background: $brand_neonyellow;
  padding: 0.5rem 1.75rem;
  border: 2px solid $brand_neonyellow;
  margin: 0 0 20px 0;
  color: $brand_black;
  border-radius: 5px;
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  text-align: center;
  cursor: pointer;
  transition: background-color $transition, color $transition, border-color $transition;
  -webkit-font-smoothing: antialiased;

  &:focus,
  &:hover {
    background-color: $brand_white;
    border-color: $brand_neonyellow;
    color: $brand_nearblack;
    outline: none;
    text-decoration: none;
  }

  @media screen and (min-width: $screen-sm-min) {
    margin: 0;
  }
}

@mixin arrow-icon {
  position: relative;
  display: block;

  &::after {
    content: '';
    height: 18px;
    width: 18px;
    display: block;
    background-image: url(../assets/images/arrow-icon.svg);
    background-size: contain;
  }
}

@mixin card {
  position: relative;
  display: block;
  width: 100%;
  background: $brand_white;
  overflow: hidden;
  border: 1px solid $gray-lighter;
  border-radius: 20px;
  padding: 2.5rem;
}

@mixin cardReadMore() {
  @include arrow-icon();

  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 4rem;
  background: linear-gradient(360deg, #FFFFFF 6.77%, rgba(255, 255, 255, 0) 100%);

  &::after {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    background-image: url(../assets/images/arrow-icon-blue.svg);
    transform: rotate(45deg);
  }

  &:focus,
  &:active,
  &:hover {
    opacity: 0.75;
  }
}

/* =================================================================================
~ Clearfix
================================================================================= */

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

/* =================================================================================
~ Bio Orbit Effect
================================================================================= */

@mixin orbits($selector, $count) {
  #{$selector} {
    &::after {
      content: "";
      display: block;
      width: 1500px;
      height: 1500px;
      border: 2px dotted $brand_neonblue;
      position: absolute;
      left: calc(-750px + 11.5%);
      top: 50%;
      z-index: 0;
      border-radius: 50%;
    }
  }

  $base-diameter: 1000px;
  $rate-of-change: 500px;
  @for $i from 1 to $count {
    :nth-last-child(#{$i}) {
      #{$selector} {
        &::after {
          content: "";
          display: block;
          width: #{$base-diameter + $rate-of-change * $i};
          height: #{$base-diameter + $rate-of-change * $i};
          border: 2px dotted $brand_neonblue;
          position: absolute;
          left: calc(
            #{($base-diameter + $rate-of-change * $i) / 2 * -1} + 11.5%
          );
          top: 50%;
          z-index: 0;
          border-radius: 50%;
        }
      }
    }
  }
}

@mixin bodyParagraph {
  color: $brand_white;
}
