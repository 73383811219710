.page-main-image {
  display: block;
  width: 100%;
  min-height: 300px;
  margin: 0 auto 3rem;
}

.page-meta {
  margin: 0 auto 3rem;

  &--published {
    color: $brand_neonyellow;
  }
}

.article-heading {
  font-weight: $font-weight-black;
  font-size: $font-size-base * 3;
  letter-spacing: .02em;
  text-transform: uppercase;
}
  
.article-sub-heading {
  font-size: $font-size-base * 1.5;
  line-height: 1;
  letter-spacing: .02em;
}

.article-blocks {
  .block-rich_text p {
    margin-bottom: 3rem;
  }
}
