.block-image {
  margin-top: 4rem;

  img {
    width: 100%;
  }
}

.thumbnail {
  &__image-link {
    img {
      margin: 0 auto;
    }
  }

  &__link {
    color: $brand_nearblack;
    font-weight: 400;
  }
}

.icon {
  display: inline-block;
}

.block-customImage {
  img {
    max-width: 100%;
  }

  .image {
    &--left {
      img {
        float: left;
      }
    }

    &--center {
      img {
        margin: auto;
      }
    }

    &--right {
      img {
        float: right;
      }
    }
  }
}
