.block-pastEvents {
  margin-bottom: 2em;
}

.past-events {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.past-event {
  &__link {
    @include btn();
    
    margin: 0 20px 20px 0;

    @media screen and (min-width: $screen-sm-min) {
      flex-direction: row;
      max-width: 350px;
      margin: 0 20px 0 0;
    }
  }
}
