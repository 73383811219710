.block-rich_text {
  margin: 0 auto 4rem;

  &_wide {
    max-width: 100rem;
    margin: 0 auto 4rem;
  }

  p {
    @include bodyParagraph;
  }

  h2 {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    line-height: $font-size-base;
    margin-bottom: 2.5rem;
  }
}
