.pagination {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 3rem 0;
  list-style: none;
}

.page-item {
  display: block;
  margin: 0 1rem 1rem 0;

  &.active .page-link {
    border-color: $brand_neonyellow;
  }

  &--next,
  &--previous {
    @media screen and (max-width: $screen-xs-max) {
      flex-basis: 100%;
    }
  }
}

.page-link {
  display: block;
  width: 4rem;
  height: 4rem;
  border: 2px solid #2E96F5;
  padding: 1rem 1.25rem;
  color: $brand_white;
  border-radius: 5px;
  text-align: center;
  line-height: 1;

  transition: background-color $transition, color $transition, border-color $transition;

  &:hover,
  &:focus,
  &:active {
    border-color: $brand_neonyellow;
  }

  span {
    display: block;
    line-height: 15px;
  }
}

.page-previous,
.page-next {
  @include btn();

  min-width: 150px;
  height: 4rem;
  padding: 0.25rem 1.25rem;
  margin-bottom: 0;
  font-weight: $font-weight-base;
}
