.bio {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 5rem;

  @media screen and (min-width: $screen-md-min) {
    flex-direction: row;
    margin: 0 0 5rem;
  }

  @media screen and (min-width: $screen-xl-min) {
    margin: 0 -5% 5rem;
  }

  &__image {
    z-index: 1;
    width: 23.33333333%;
    min-width: 150px;
    margin: 0 10% 3rem 0;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    background: $brand_white;
    color: $brand_nearblack;
    padding: 2.5rem 4rem;

    @media screen and (min-width: $screen-md-min) {
      width: 66.66666667%;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 3rem);
        left: -3rem;
        width: 0;
        height: 0;
        border-right: 3rem solid $brand_white;
        border-top: 3rem solid transparent;
        border-bottom: 3rem solid transparent;
      }
    }
  }

  &__name {
    font-size: $font-size-base * 1.4;
  }

  &__description {
    font-size: $font-size-base * 0.75;
  }
}
