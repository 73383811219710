.block-column {
  width: 100%;

  &--center {
    text-align: center;

    .image img {
      margin: auto;
    }
  }

  &--partners {
    .block-customImage {
      margin-bottom: 10rem;
    }
  }

  &--partners-small {
    .block-customImage {
      margin-bottom: 2rem;
    }
  }

  &--you-are-here {
    .block-richText .content {
      h2 {
        font-size: $font-size-base * 2.5;
      }
    }
    .block-customImage {
      img {
        border-radius: 50%;
        border: 1px solid $brand_neonblue;
        padding: 10px;
        background: $brand_nearblack;
      }

      .image {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -24px;
          left: calc(50% - 0.5px);
          display: block;
          width: 1px;
          height: 24px;
          background: $brand_neonblue;
        }
      }
    }
  }
}

$grid__cols: 12;
@for $i from 1 through $grid__cols {
  .block-column--#{$i} {
    @media screen and (min-width: $screen-sm-min) {
      width: (100 / ($grid__cols / $i) ) * 1%;
    }
  }
}
