.sub-menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  color: $brand_nearblack;
  font-size: $font-size-base * 1.6;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.5px;

  &--slide-down {
    margin: 2rem 0 0 0;
  }

  @media screen and (min-width: $screen-menu-toggle) {
    background: $brand_white;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25), inset 0 4px 5px 0 rgba(0, 0, 0, 0.25);
    font-size: $font-size-base * 1.1;
    padding: 2rem 0;

    &--slide-down {
      position: absolute;
      left: 2.5rem;
      right: 2.5rem;
      top: 100%;
      z-index: -1;
      overflow: hidden;
      height: 0;
      margin: 0;
      opacity: 0;
      transform: translate3d(0, 5px, 0);
      transition: transform $transition, opacity $transition;
    }
  }

  &__parent {
    display: none;
    margin: 1rem 0;
    border-bottom: 4px solid transparent;
    font-weight: $font-weight-demi;
    font-size: $font_size_base * 1.1;
    text-transform: none;
    letter-spacing: 0.05em;

    @media screen and (min-width: $screen-menu-toggle) {
      display: inline-block;
      margin: 0;
    }
  }

  &__item {
    display: block;
    list-style: none;
    text-align: left;
    margin: 0 0 2rem 0;

    @media screen and (min-width: $screen-menu-toggle) {
      margin: 0;
    }

    &.active {
      .sub-menu__link {
        border-color: $brand_nearblack;

        @media screen and (min-width: $screen-menu-toggle) {
          color: $brand_nearblack;
        }
      }
    }
  }

  &__link {
    display: block;
    color: $brand_white;
    transition: background-color $transition, color $transition;
    text-transform: none;
    font-weight: $font-weight-demi;
    font-size: 1.8rem;

    @media screen and (min-width: $screen-menu-toggle) {
      padding: 1rem 2rem;
      color: $brand_nearblack;

      &:hover,
      &:focus,
      &:active {
        background: $gray-lightest;
        color: lighten($brand_nearblack, 25%);
        text-decoration: none;
      }

      &.active {
        &:hover {
          color: lighten($brand_nearblack, 25%);
        }
      }
    }
  }
}
