/* =================================================================================
~ Fonts
================================================================================= */

// Google Fonts Fira Sans, Fira Code, and Overpas import

// font-family: 'Fira Code', monospace;
// font-family: 'Fira Sans', sans-serif;
// font-family: 'Overpass', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;600;700&family=Fira+Sans:wght@100;300;400;600;700;900&family=Overpass:wght@100;300;400;600;700;900&display=swap');

// SVG Icons

[class^="icon-"] {
  path {
    fill: $brand_white;
    transition: fill $transition;
  }
  &:hover g {
    opacity: 1;
  }
}
