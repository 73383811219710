.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 30px 0;
  color: $brand_white;
  
  // Set to none to allow clickable links in the globe animation
  pointer-events: none; 

  @media screen and (min-width: $screen-md-min) {
    padding: 40px 0;
  }

  .site-logo {
    display: block;
    max-width: 260px;
    width: 100%;
    margin: auto;

    // Header sets this to none, so we need to set it to all to override that
    pointer-events: auto;

    @media screen and (min-width: $screen-sm-min) {
      margin: 0;
    }

    svg {
      display: block;
      width: 100%;
    }
  }
}

.main-nav {
  position: relative;
  display: none;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  background: $brand_neonyellow;
  color: $brand_black;
  border-radius: 40px;
  font-size: 1.6rem;
  transition: opacity $transition;

  // Header sets this to none, so we need to set it to all to override that
  pointer-events: all;

  @media screen and (min-width: $screen-menu-toggle) {
    display: flex;
    max-width: 100%;
    z-index: 1;
    flex-wrap: nowrap;
    visibility: visible;
    padding: 0;
    opacity: 1;
  }

  .js-active & {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    overflow: scroll;
    visibility: visible;
    background: rgba($brand_nearblack, 0.95);
    opacity: 1;
    border-radius: 0;
    padding: 6rem 0;
    font-size: $font-size-base * 1.8;
  }

  &__toggle {
    position: absolute;
    top: 1.6rem;
    right: 2rem;
    z-index: 999;
    background: none;
    box-shadow: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 2.6rem;
    transition: top $transition;

    // Header sets this to none, so we need to set it to all to override that
    pointer-events: auto;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      background: none;
      box-shadow: none;
    }

    @media screen and (min-width: $screen-menu-toggle) {
      display: none;
    }
  }
}
