.social-links {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    list-style: none;
  }

  &__heading {
    display: block;
    margin-bottom: 0.5rem;
    color: $brand_white;
    font-size: $font-size-base;
  }
}

.icon-link {
  display: block;

  &:hover,
  &:active {
    path {
      fill: $brand_neonyellow;
    }
  }

  &--facebook {
    &:hover,
    &:active {
      path {
        fill: $vendor_facebook;
      }
    }
  }

  &--instagram {
    &:hover,
    &:active {
      path {
        fill: $vendor_instagram;
      }
    }
  }

  &--twitter {
    &:hover,
    &:active {
      path {
        fill: $vendor_twitter;
      }
    }
  }

  &--tumblr {
    &:hover,
    &:active {
      path {
        fill: $vendor_tumblr;
      }
    }
  }

  &--youtube {
    &:hover,
    &:active {
      path {
        fill: $vendor_youtube;
      }
    }
  }
}

.page-menu--icons {
  .social-links__link {
    margin-right: 50px;

    &:last-child {
      margin: 0;
    }
  }

  .icon-link {
    path {
      fill: $brand_neonyellow;
    }
  }
}
