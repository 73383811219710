.container {
  width: 100%;
  margin: auto;
  padding: 0 2rem;
  max-width: $screen-lg-desktop;

  &--md {
    max-width: $screen-desktop;
  }

  &--xl {
    max-width: $screen-xl-desktop;
  }

  &--fullWidth {
    max-width: 100%;
    padding: 0;
  }

  @media screen and (min-width: $screen-sm-min) {
    padding: 0 5rem;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;

  &--center {
    align-items: center;
  }

  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items: center;
  }
}
