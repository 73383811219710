.footer {
  min-height: 20vh;
  background: $brand_black;
  color: $brand_white;
  padding: 4.8rem 0;
  -webkit-font-smoothing: antialiased;

  &__content {
    display: flex;
    align-items: center;
    font-size: $font-size-base * 0.9;

    &--left {
      flex-direction: column;
      width: 60%;
      display: flex;
      justify-content: space-between;

      @media screen and (min-width: $screen-sm-min) {
        flex-direction: row;
      }
    }
  }

  &__social {
    display: flex;
    align-items: flex-start;
    padding-top: 2rem;
    flex-direction: column;

    @media screen and (min-width: $screen-md-min) {
      padding-top: 0;
    }
  }

  &__copy {
    p {
      margin: 0 0 0 30px;
      font-size: $font-size-base;
      line-height: 1.4;
      font-weight: $font-weight-light;
    }

    a {
      color: $brand_white;
      text-decoration: underline;

      &:hover {
        color: darken($brand_white, 35%);
      }
    }
  }
}
