.footer-menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  margin: 0;

  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items: center;
  }

  &__item {
    display: block;
    list-style: none;
    margin: 0;

    @media screen and (min-width: $screen-sm-min) {
      margin: 0 3rem 0 0;
      text-align: right;
    }
  }

  &__link {
    position: relative;
    display: block;
    padding: 0;
    color: $brand_white;
    border-bottom: 1px solid $brand_white;
    font-size: $font-size-base * 0.9;
    font-weight: $font-weight-bold;
    text-transform: uppercase;

    transition: color $transition, border-color $transition;

    &:focus,
    &:hover,
    &:active {
      color: darken($brand_white, 35%);
      border-bottom-color: darken($brand_white, 35%);
    }
  }
}
