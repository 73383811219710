.glide {
  padding-bottom: 1rem;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    
  }

  &__track {
    overflow: visible;

    .glide__slides {
      overflow: visible;
      margin-bottom: 30px;

      .glide__slide {
        height: auto;
      }
    }
  }

  &__arrows {
    opacity: 0;
    transition: opacity $transition;
  }

  &:hover,
  &:focus {
    .glide__arrows {
      opacity: 1;
    }
  }

  &__arrow {
    @include arrow-icon();

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 3.5rem);
    width: 5rem;
    height: 5rem;
    border: none;
    margin: 1rem 3rem 2rem;
    padding: 0 1rem;
    background: $brand_neonyellow;
    color: $brand_black;
    line-height: 1;
    font-size: $font-size-base * 1.3;
    box-shadow: none;
    border-radius: 50%;

    &:focus,
    &:active,
    &:hover {
      background: lighten($brand_neonyellow, 25%);
      outline: none;
      box-shadow: none;
      text-decoration: none;
    }

    &--right {
      right: 0;

      &::after {
        display: block;
      }
    }

    &--left {
      left: 0;

      &::after {
        transform: rotate(180deg);
      }
    }

    @media screen and (min-width: $screen-sm-min) {
      top: calc(50% - 5rem);
      bottom: 0;
    }
  }
}
