.block-eventStatistics {
  margin-bottom: 2em;
}

.statistics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.statistic {
  margin-right: 80px;

  &__link {
    @include btn();
  }

  &__text {
    font-family: $font-family-monospace;
    color: $brand_white;
    font-size: $font-size-base * 4;
    font-weight: $font-weight-bold;
    line-height: 1.5;
  }

  &__label {
    display: block;
    font-family: $font-family-base;
    color: $brand_neonyellow;
    letter-spacing: 0.1em;
    font-size: $font-size-base * 1.2;
    line-height: 1.5;
    text-transform: uppercase;
  }
}
