$page-heading-width: 716px;

.page {
  position: relative;
  overflow: hidden;
}

.page-header-offset {
  display: block;
  width: 100%;
  height: 190px;
}

.page-header {
  margin: 0 0 4rem 0;
}

.page-heading {
  max-width: $page-heading-width;
  font-weight: $font-weight-black;
  font-size: $font-size-base * 3;
  letter-spacing: .02em;
  text-transform: uppercase;
}
  
.page-sub-heading {
  max-width: $page-heading-width;
  font-size: $font-size-base * 2.1;
  color: $brand_neonyellow;
  line-height: 1;
  letter-spacing: .02em;
}

.page-header--image {
  display: flex;
  width: 100%;
  margin: 0 auto 6rem;
  padding: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
}

.page-content {
  min-height: 80vh;
  padding: 2rem 0;
}

.content-blocks {
  &--heading {
    letter-spacing: .05em;
  }
}

.section-content {
  display: flex;
  overflow: hidden;
  min-height: 80vh;
  padding: 2rem 0;

  @media screen and (min-width: $screen-md-min) {
    padding: 8rem 0;
  }

  .block-richText,
  .block-cta {
    margin-bottom: 2em;
  }

  &--default {
    h2 {
      margin: 0;
      font-size: $font-size-base * 3.2;
      font-weight: $font-weight-black;
      letter-spacing: 0.05em;
      line-height: 1.2;
      text-transform: uppercase;
    }
  }

  &--orbits {
    background-color: $brand_nearblack;
    background-image: url('../assets/images/bkgd_orbits.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    & .content {
      color: $brand_white;

      h2 {
        margin: 0;
        font-size: $font-size-base * 3.2;
        font-weight: $font-weight-black;
        letter-spacing: 0.05em;
        line-height: 1.2;
        text-transform: uppercase;
      }

      h3 {
        color: $brand_white;
        margin: 0;
        font-size: $font-size-base * 2.1;
        font-weight: $font-weight-base;

        strong,
        b {
          color: $brand_nasared;
          font-weight: $font-weight-base;
        }
      }
      
      h4 {
        font-family: $font-family-base;
        margin: 0;
        letter-spacing: 0.1em;
        font-size: $font-size-base * 1.2;
        line-height: 1.5;
        text-transform: uppercase;
      }

      p {
        font-size: $font-size-base;
        line-height: 1.5;
      }

      a:not(.cta) {
        display: inline-block;
        color: $brand_neonyellow;
        border-bottom: 2px solid $brand_neonyellow;

        &:hover,
        &:focus,
        &:active {
          color: $brand_white;
          border-bottom-color: $brand_white;
        }
      }
    }
  }

  &--grid {
    background-color: $brand_white;
    background-image: url('../assets/images/bkgd_grid.svg');
    background-repeat: no-repeat;    
    background-size: contain;
    background-position: bottom right;

    & .content {
      color: $gray-darker;

      h2 {
        color: $brand_nearblack;
        margin: 0 0 0.5rem 0;
        font-size: $font-size-base * 3.2;
        font-weight: $font-weight-black;
        letter-spacing: 0.05em;
        line-height: 1.2;
        text-transform: uppercase;
      }

      h3 {
        color: $brand_nearblack;
        margin: 0 0 4rem 0;
        font-size: $font-size-base * 2.1;
        font-weight: $font-weight-base;

        strong,
        b {
          color: $brand_nasared;
          font-weight: $font-weight-base;
        }
      }
      
      h4 {
        font-family: $font-family-base;
        margin: 0;
        letter-spacing: 0.1em;
        font-size: $font-size-base * 0.9;
        font-weight: $font-weight-bold;
        line-height: 1.5;
        text-transform: uppercase;
      }

      p {
        font-size: $font-size-base;
        line-height: 1.5;
      }

      a:not(.cta) {
        display: inline-block;
        color: $brand_neonblue;
        border-bottom: 2px solid $brand_neonblue;

        &:hover,
        &:focus,
        &:active {
          color: $brand_mediumred;
          border-bottom-color: $brand_mediumred;
        }
      }
    }
  }

  &--waves {
    background-image: url('../assets/images/bkgd_waves.svg');
    background-color: $brand_electricblue;
    background-repeat: no-repeat;
    background-size: cover;

    & .content {
      color: $brand_white;

      h2 {
        margin: 0;
        font-size: $font-size-base * 3.2;
        font-weight: $font-weight-black;
        letter-spacing: 0.05em;
        line-height: 1.2;
        text-transform: uppercase;
      }

      h3 {
        color: $brand_neonyellow;
        margin: 0;
        font-size: $font-size-base * 2.1;
        font-weight: $font-weight-base;

        strong,
        b {
          color: $brand_nasared;
          font-weight: $font-weight-base;
        }
      }
      
      h4 {
        font-family: $font-family-base;
        margin: 0;
        letter-spacing: 0.1em;
        font-size: $font-size-base * 1.2;
        line-height: 1.5;
        text-transform: uppercase;
      }

      p {
        font-size: $font-size-base;
        line-height: 1.5;
      }

      a:not(.cta) {
        display: inline-block;
        color: $brand_neonyellow;
        border-bottom: 2px solid $brand_neonyellow;

        &:hover,
        &:focus,
        &:active {
          color: $brand_white;
          border-bottom-color: $brand_white;
        }
      }
    }
  }

  &--rose {
    background-image: url('../assets/images/bkgd_spirals.svg');
    background-color: $brand_nearblack;
    background-repeat: no-repeat;
    background-size: cover;

    & .content {
      color: $brand_white;

      h2 {
        margin: 0 0 1rem 0;
        font-size: $font-size-base * 3.2;
        font-weight: $font-weight-black;
        letter-spacing: 0.05em;
        line-height: 1.2;
        text-transform: uppercase;
      }

      h3 {
        color: $brand_white;
        margin: 0 0 2rem 0;
        font-size: $font-size-base * 2.1;
        font-weight: $font-weight-base;

        strong,
        b {
          color: $brand_nasared;
          font-weight: $font-weight-base;
        }
      }
      
      h4 {
        font-family: $font-family-base;
        margin: 0;
        letter-spacing: 0.1em;
        font-size: $font-size-base * 1.2;
        line-height: 1.5;
        text-transform: uppercase;
      }

      p {
        font-size: $font-size-base;
        line-height: 1.5;
      }

      a:not(.cta) {
        display: inline-block;
        color: $brand_neonyellow;
        border-bottom: 2px solid $brand_neonyellow;

        &:hover,
        &:focus,
        &:active {
          color: $brand_white;
          border-bottom-color: $brand_white;
        }
      }
    }
  }

  &--binary {
    background-image: url('../assets/images/bkgd_binary.png');
    background-color: $brand_electricblue;
    background-repeat: no-repeat;
    background-size: cover;

    & .content {
      color: $brand_white;

      h2 {
        margin: 0;
        font-size: $font-size-base * 3.2;
        font-weight: $font-weight-black;
        letter-spacing: 0.05em;
        line-height: 1.2;
        text-transform: uppercase;
      }

      h3 {
        color: $brand_neonyellow;
        margin: 0;
        font-size: $font-size-base * 2.1;
        font-weight: $font-weight-base;

        strong,
        b {
          color: $brand_nasared;
          font-weight: $font-weight-base;
        }
      }
      
      h4 {
        font-family: $font-family-base;
        margin: 0;
        letter-spacing: 0.1em;
        font-size: $font-size-base * 1.2;
        line-height: 1.5;
        text-transform: uppercase;
      }

      p {
        font-size: $font-size-base;
        line-height: 1.5;
      }

      a:not(.cta) {
        display: inline-block;
        color: $brand_neonyellow;
        border-bottom: 2px solid $brand_neonyellow;

        &:hover,
        &:focus,
        &:active {
          color: $brand_white;
          border-bottom-color: $brand_white;
        }
      }
    }
  }

  &--dark {
    background: $brand_nearblack;
    color: $white;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;

    .title--h2 {
      color: $white;
      font-weight: $font-weight-light;
      line-height: 1.5;
      margin-bottom: 1em;
      text-transform: none;
      letter-spacing: 1.8px;
      font-size: $font-size-base * 2.15;
    }

    & .block-content{
      & p,
      h4 {
        font-weight: $font-weight-light;
        line-height: 1.4;
      }
      & h4 {
        margin-bottom: 4em;
        margin-top: 2em;
        & a {
          color: $brand_white;
        }
      }
    }
  }

  &--gray {
    background: $gray-lightest;
    color: $brand_nearblack;

    .title--h2 {
      color: $brand_nearblack;
      letter-spacing: 0.02em;
      font-size: $font-size-base * 1.7;
      text-transform: none;
      margin: 5rem 0;
    }
    & .block-content {
      & p {
        color: $brand_nearblack;
        font-size: $font-size-base * 1.05;
        font-weight: $font-weight-base;
        line-height: 1.3;
        letter-spacing: 0.01em;
      }
    }
    padding-bottom: 20px;
  }
}
