.project-thumbnail {
  @include card();
  
  margin: 1rem;

  &__image-link {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    transition: transform $transition;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        $brand_neonblue,
        lighten($brand_neonblue, 13.5%)
      );
      opacity: 0;

      transition: opacity $transition;
    }

    &:hover,
    &:active {
      transform: scale(1.01);
    }

    img {
      width: 100%;
    }
  }

  &__link {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    color: $brand_nasared;
    white-space: nowrap;
    text-transform: capitalize;
    letter-spacing: 0.01em;
    font-size: $font_size_base * 1.2;
    text-overflow: ellipsis;

    &:hover,
    &:active {
      color: $brand_electricblue;
      text-decoration: none;
    }
  }

  &__icon-link {
    @include cardReadMore();
  }

  &__title {
    display: block;
    margin-bottom: 1rem;
    font-size: $font-size-base * 1.2;
    line-height: 1.4;
  }

  &__team,
  &__location {
    display: block;
    font-size: $font-size-base * 0.8;
    letter-spacing: 0.01em;
    line-height: 1.4;
    color: $gray;
  }
  &__location {
    padding-bottom: 3rem;
  }
}
