.cta:not(.cta--image) {
  @include btn();

  @media screen and (max-width: $screen-sm-max) {
    font-size: $font-size-base * 0.9;
  }
  
  &--full {
    display: block;
  }

  &--arrow {
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    &::after {
      content: "\2192";
      display: inline-block;
      margin: 0 0 0 1rem;
    }
  }

}

.cta--image {
  display: block;

  img {
    width: 100%;
  }

  .thumbnail {
    max-width: 120px;
  }

  .small {
    max-width: 120px;
  }

  .medium {
    max-width: 300px;
  }

  .large {
    max-width: 500px;
  }
}

.block-cta {
  margin-bottom: 2em;
}
