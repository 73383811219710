.block-recentArticles {
  margin-bottom: 2em;
}

.posts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.recent-posts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: $brand_black;

  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row;
  }
}

.post-listing {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 11rem;

  @media screen and (min-width: $screen-md-min) {
    flex-direction: row;
  }

  &__left-col {
    width: 100%;
    max-width: 660px;

    @media screen and (min-width: $screen-md-min) {
      padding-right: 24px;
    }
  }

  &__right-col {
    width: 100%;
    max-width: 740px;
  }

  &__image-link {
    display: block;
    position: relative;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 24px;
    transition: transform $transition;

    &:hover,
    &:active {
      transform: scale(1.01);
    }
  }

  &__date {
    display: block;
    margin: 0 0 5px 0;
    font-weight: $font-weight-bold;
    letter-spacing: 0.05em;
    line-height: 1.4;
    color: $brand_neonyellow;
    text-transform: uppercase;
  }

  &__title {
    display: block;
    margin: 0;
    font-family: $font-family-base;
    font-size: $font-size-base * 1.6;
    font-weight: $font-weight-black;
    line-height: 1.4;
    text-transform: uppercase;

    a {
      color: $brand_white;
    }

    a:hover {
      color: $brand_neonyellow;
    }
  }

  &__subtitle {
    display: block;
    margin: 0 0 30px 0;
    font-size: $font-size-base * 1.2;
    line-height: 1.2;
    color: $brand_neonyellow;
  }

  &__author {
    display: block;
    margin: 0;
    line-height: 1.4;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__excerpt {
    display: block;
    margin: 0 0 30px 0;
  }
}

.post-card {
  @include card();

  max-width: 100%;
  height: 270px;
  margin: 0 20px 20px 0;

  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row;
    max-width: 350px;
  }

  &__image-link {
    display: block;
    position: relative;
    width: 100%;
    margin: 0 0 15px 0;
    transition: transform $transition;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        $brand_neonblue,
        lighten($brand_neonblue, 13.5%)
      );
      opacity: 0;

      transition: opacity $transition;
    }

    &:hover,
    &:active {
      transform: scale(1.01);
    }

    img {
      width: 100%;
    }
  }

  &__link {
    display: block;
    width: 100%;
    overflow: hidden;
    color: $brand_nasared;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover,
    &:active {
      color: $brand_electricblue;
      text-decoration: none;
    }
  }

  &__icon-link {
    @include cardReadMore();
  }

  &__title {
    display: block;
    margin: 0 0 15px 0;
    font-family: $font-family-base;
    font-size: $font-size-base * 1.2;
    font-weight: $font-weight-demi;
    line-height: 1.4;
  }

  &__date {
    display: block;
    margin: 0 0 15px 0;
    font-size: $font-size-base * 0.8;
    letter-spacing: 0.03em;
    line-height: 1.4;
    color: $gray;
  }

  &__subtitle {
    display: block;
    margin: 0 0 15px 0;
    font-size: $font-size-base * 0.8;
    font-weight: $font-weight-bold;
    letter-spacing: 0.03em;
    line-height: 1.4;
    color: $gray;
  }

  &__excerpt {
    display: block;
    font-size: $font-size-base * 0.7;
    line-height: 1.4;
    color: $gray;
  }
}
