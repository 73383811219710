.banners {
  background-color: $brand_neonyellow;
  color: $brand_black;
  position: relative;
  z-index: 1;
}

.banner {
  padding: 1.3rem 0;
  font-size: $font-size-base * .9;
  letter-spacing: .01em;
  line-height: 19px;

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: $screen-sm-min) {
      flex-direction: row;
    }
  }

  &__title {
    color: $black;
    font-weight: $font-weight-normal;
    padding-left: 3rem;
    position: relative;

    &::before {
      content: "\25C0";
      display: inline-block;
      font-size: $font-size-base * .7;
      position: absolute;
      left: 0;
      transform: rotate(-90deg);
      transition: transform $transition;
    }

    &.active {
      &::before {
        transform: rotate(-270deg);
      }
    }
  }

  &__link {
    color: $black;
    font-weight: $font-weight-demi;
    border-bottom: 1px solid transparent;
    white-space: nowrap;
    align-self: flex-start;
    margin-top: 1rem;
    display: block;

    &::after {
      content: "\2192";
      display: inline-block;
      margin: 0 0 0 1rem;
    }

    &:hover {
      border-bottom: 1px solid $brand_black;
    }

    @media screen and (min-width: $screen-lg-min) {
      margin-top: 0;
      margin-left: .5rem;
      display: inline-block;
    }
  }

  &__content {
    padding-top: 2.3rem;
    padding-bottom: .6rem;
  }
}

.toggle-content {
  display: none;
  height: 0;
  overflow: hidden;
  transition: height 350ms ease-in-out;

  &.is-visible {
    display: block;
    height: auto;
  }
}
