.block-flexbox {
  margin-bottom: 2em;
}

.flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  
  &--space-between {
    justify-content: space-between;
  }

  &--hero-footer {
    .flexbox__item .content {
      h4 {
        margin: 0 0 0 20px;
        display: inline-block;
      }
    }
  }
}
