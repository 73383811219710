.content-blocks {
  > * {
    margin-bottom: 8rem;
  }
}

.content {
  &--left {
    text-align: left;

    .responsive-object-wrap {
      margin: 0 auto 1.5rem 0;
    }
  }

  &--center {
    text-align: center;

    .responsive-object-wrap {
      margin: 0 auto 1.5rem;
    }
  }

  &--right {
    text-align: right;

    .responsive-object-wrap {
      margin: 0 0 1.5rem auto;
    }
  }
}
