fieldset {
  border: none;
}

input,
input[type="text"],
input[type="email"],
input[type="password"] {
  z-index: 2;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  background-color: transparent;
  color: $brand_nearblack;
  margin: 0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-style: italic;
  appearance: none;

  &:focus {
    outline: none;
  }

  // Small and larger
  @media screen and (min-width: $screen-sm-min) {
    font-size: $font-size-base * 1;
  }
}

textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: $brand_nearblack;
  padding-top: 12px;
  margin: 0;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-style: italic;
  appearance: none;

  &:focus {
    outline: none;
  }

  // Small and larger
  @media screen and (min-width: $screen-sm-min) {
    font-size: $font-size-base * 1;
  }
}

label {
  font-weight: $font-weight-bold;
  display: block;
  color: $brand_nearblack;
  font-size: 14px;
  line-height: 16px;

  transition: padding 200ms ease-in-out;
}

textarea {
  line-height: 1.2;
}

button[type="submit"] {
  width: auto;
}

input[placeholder] {
  text-overflow: ellipsis;
}
::-moz-placeholder {
  text-overflow: ellipsis;
}
input:-moz-placeholder {
  text-overflow: ellipsis;
}

::placeholder {
  color: $gray-lighter;
  text-overflow: ellipsis;
}

.form {
  max-width: 768px;
}

.form-sm {
  max-width: 600px;
}

.form-lg {
  max-width: 980px;
}

.form-lx {
  max-width: 1200px;
}

.form-full {
  max-width: 100%;
}

.fieldWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 60px;
  margin-bottom: 1.5rem;
  background-color: $brand_white;
  border: 2px solid $brand_nearblack;
  border-radius: 5px;
  padding: 11px 16px;
  overflow: hidden;

  transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;
}

