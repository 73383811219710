.scroll-navigation {
  position: fixed;
  z-index: 20;
  top: calc(50% - 175px);
  right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  height: 350px;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: calc(50% - 1px);
    display: block;
    width: 2px;
    background-color: $brand_electricblue;
  }
}

.anchor-nav {
  position: relative;
  text-align: center;
  height: 1rem;
  width: 100%;
  background: none;
  border: none;

  &__title {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 51%;
    background: $brand_neonyellow;
    border-left: 1px solid $brand_electricblue;
    border-top: 1px solid $brand_electricblue;
    border-bottom: 1px solid $brand_electricblue;
      
    transition: background-color $transition;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    background: $brand_neonyellow;
    border-right: 1px solid $brand_electricblue;
    border-top: 1px solid $brand_electricblue;
    border-bottom: 1px solid $brand_electricblue;

    transition: background-color $transition;
  }

  &:hover,
  &:focus,
  &:active {
    background: none;
    border: none;

    &::before,
    &::after {
      background: $brand_nearblack;
    }
  }

  &--prev {
    &::before {
      transform: skew(0deg, -18deg);
    }

    &::after {
      transform: skew(0deg, 18deg);
    }
  }

  &--next {
    &::before {
      transform: skew(0deg, 18deg);
    }

    &::after {
      transform: skew(0deg, -18deg);
    }
  }
}

.anchor-link {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: $brand_neonyellow;
  border: 1px solid $brand_electricblue;
  border-radius: 50%;

  transition: background-color $transition;

  &:hover,
  &:focus,
  &:active {
    background-color: $brand_nearblack;
  }

  &__title {
    display: none;
  }

  &.anchor-link--active {
    background-color: $brand_nearblack;
  }
}
